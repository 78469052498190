<template>
  <v-container>
    <div class="table__spinnerWrapper" v-if="loading">
                    <app-spinner></app-spinner>
    </div>
    <v-col v-else>
      <v-row>
        <v-col md="3">
                <AutoCompleteField :label="$t('teacher name')" v-model="filters.teacher" clearable
                    endPoint="/teacher/auto-complete" />
            </v-col>
        <v-col md="3">
                <AutoCompleteField :label="$t('student name')" v-model="filters.student_id" clearable
                    endPoint="/student/auto-complete" />
            </v-col>
            
            <v-col md="3">
                <AutoCompleteField :label="$t('educitional class')" v-model="filters.edu_class" clearable
                    endPoint="/edu-class" />
            </v-col>
            <v-col md="3">
                <AutoCompleteField :label="$t('class')" v-model="filters.grade" clearable
                    endPoint="/grade" />
            </v-col>
            <v-col md="3">
                <AutoCompleteField :label="$t('subject')" v-model="filters.subject" clearable
                    endPoint="/subject" />
            </v-col>
            <v-col md="9"></v-col>
            <v-col md="3" class="mb-3 py-0">
                <v-btn color="green" dark block @click="getAll(1)">{{ $t('search') }}</v-btn>
            </v-col>
            <v-col md="3" class="mb-3 py-0">
                <v-btn color="red" dark block @click="clearFilter">{{ $t('Delete Search') }}</v-btn>
            </v-col>
            <v-col md="3" class="mb-3 py-0" >
                <ExportExcel end-point="/ai/grades/details/group/export" :params="filters" />
            </v-col>
      </v-row>
      <v-row>
        <template>
          <v-col md="3" v-for="(value, idx) of tableItems" :key="idx">
            <v-card class="add-user__chips-wrapper_Up">
              <v-card-title >
                  <v-row >
                    <v-col md="3" v-if="value.photo">
                      <v-avatar size="50" >
                        <v-img :src="value.photo"></v-img>
                      </v-avatar>
                    </v-col>
                    <v-col md="3" v-else>
                      <v-avatar size="50" >
                        <v-img src="@/assets/userphoto.png"></v-img>
                      </v-avatar>
                    </v-col>
                    <v-col class="px-5" md="9">
                          <h5>
                            {{ $t('specialization') }} :
                            <span style="font-weight: 100">{{ value.specialization }}</span>
                          </h5>
                    </v-col>
                  </v-row>
                <v-row class="justify-center">
                  <h3>{{ value.name }}</h3>
                </v-row>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col md="3" style="font-size: 6px; text-align: center;">
                    <PercentageCircle :darkMode="$store.state.isDarkMode" :refresh-rate="20" style="font-size: 40px;"
                      animate="true" complete-color="black" :percent="value.engagement" />
                    <span class="justify-center">{{ $t('Participate') }}</span>
                  </v-col>
                  <v-col md="3" style="font-size: 7px; text-align: center;">
                    <PercentageCircle :darkMode="$store.state.isDarkMode" :refresh-rate="20" style="font-size: 40px;"
                      animate="true" complete-color="black" :percent="value.happy" />
                    <span class="justify-center">{{ $t('Happy') }}</span>
                  </v-col>
                  <v-col md="3" style="font-size:  7px; text-align: center;">
                    <PercentageCircle :darkMode="$store.state.isDarkMode" :refresh-rate="20" style="font-size: 40px;"
                      animate="true" complete-color="black" :percent="value.neutral" />
                    <span class="justify-center">{{ $t('Neutral') }}</span>
                  </v-col>
                  <v-col md="3" style="font-size:  7px; text-align: center;">
                    <PercentageCircle :darkMode="$store.state.isDarkMode" :refresh-rate="20" style="font-size: 40px;"
                      animate="true" complete-color="black" :percent="value.surprise" />
                    <span class="justify-center">{{ $t('Surprise') }}</span>
                  </v-col>
                  <v-col md="3" style="font-size:  7px; text-align: center;">
                    <PercentageCircle :darkMode="$store.state.isDarkMode" :refresh-rate="20" style="font-size: 40px;"
                      animate="true" complete-color="black" :percent="value.angry" />
                    <span class="justify-center">{{ $t('Angry') }}</span>
                  </v-col>
                  <v-col md="3" style="font-size:  7px; text-align: center;">
                    <PercentageCircle :darkMode="$store.state.isDarkMode" :refresh-rate="20" style="font-size: 40px;"
                      animate="true" complete-color="black" :percent="value.sad" />
                    <span class="justify-center">{{ $t('Sad') }}</span>
                  </v-col>
                  <v-col md="3" style="font-size:  7px; text-align: center;">
                    <PercentageCircle :darkMode="$store.state.isDarkMode" :refresh-rate="20" style="font-size: 40px;"
                      animate="true" complete-color="black" :percent="value.fear" />
                    <span>{{ $t('Fear') }}</span>
                  </v-col>
                  <v-col md="3" style="font-size:  7px; text-align: center;">
                    <PercentageCircle :darkMode="$store.state.isDarkMode" :refresh-rate="20" style="font-size: 40px;"
                      animate="true" complete-color="black" :percent="value.disgust" />
                    <span class="justify-center">{{ $t('Disgust') }}</span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </template>
      </v-row>
    </v-col>
    <div class="d-flex justify-center mt-5">
        <v-row>
            <v-col md="5 ">
                <v-switch :dark="$store.state.isDarkMode" @change="goToAll" v-model="is_all" color="green" :label="$t('Show All')"></v-switch>
            </v-col>
            <pagination v-if="(!loading & (paginated))" :disable-pagination="!paginated" :totalPages="total"
                :page="page" @PaginationValue="applyPagination($event)"/>
        </v-row>
    </div>

    <!-- <v-col md="4">
      <PercentageCircle  :refresh-rate="20" style="font-size: 50px;"  animate="true" 
      complete-color="black" :percent="10" />
    </v-col>   -->
  </v-container>
</template>

<script>
import PercentageCircle from 'vue-css-percentage-circle';
import axios from 'axios'
import spinner from "../../../../../components/base/spinner.vue";
import pagination from "../../../../../components/base/pagination.vue";
import AutoCompleteField from "../../../components/core/AutoCompleteField.vue";
import ExportExcel from "../../../components/core/ExportExcel.vue";
import i18n from '../../../../../i18n';

export default {
  components: {
    PercentageCircle,
    AutoCompleteField,
    spinner,
    ExportExcel,
    pagination,
  },
  data: () => ({
    is_all: false,
    paginated: true,
    tableItems: [],
    loading: false,
    page: 1,
    total: 1,
    tableHeaders: ["date", "student name", "Participate", "Angry", "Disgust", "Fear", "Happy", "Sad", "Surprise", "Neutral"],
    motion: [],
    itemsMotion: [
      {
        name: i18n.t('Participate'),
        value: "engagement",
      },
      {
        name: i18n.t('Angry'),
        value: "angry",
      },
      {
        name: i18n.t('Disgust'),
        value: "disgust",
      },
      {
        name: i18n.t('Fear'),
        value: "fear",
      },
      {
        name: i18n.t('Neutral'),
        value: "neutral",
      },
      {
        name: i18n.t('Surprise'),
        value: "surprise",
      },
      {
        name: i18n.t('Sad'),
        value: "sad",
      },
      {
        name: i18n.t('Happy'),
        value: "happy",
      },

    ],
    filters: {
      student_id: null,
      edu_class: null,
      grade: null,
      from: null,
      to: null,
      subject: null,
      engagement: null,
      lesson_number: null,
      angry: null,
      disgust: null,
      fear: null,
      happy: null,
      sad: null,
      surprise: null,
      neutral: null,
      teacher: null,
    },
  }),
  watch: {
    "motion": function () {
      this.filters.angry = undefined;
      this.filters.disgust = undefined;
      this.filters.fear = undefined;
      this.filters.happy = undefined;
      this.filters.sad = undefined;
      this.filters.neutral = undefined;
      this.filters.surprise = undefined;
      this.filters.engagement = undefined;
      this.motion.forEach((element) => {
        if (element === "fear") {
          this.filters.fear = 1;
        }
        if (element === "happy") {
          this.filters.happy = 1;
        }
        if (element === "sad") {
          this.filters.sad = 1;
        }
        if (element === "surprise") {
          this.filters.surprise = 1;
        }
        if (element === "disgust") {
          this.filters.disgust = 1;
        }
        if (element === "angry") {
          this.filters.angry = 1;
        }
        if (element === "engagement") {
          this.filters.engagement = 1;
        }
        if (element === "neutral") {
          this.filters.neutral = 1;
        }
      });
    },
  },
  methods: {
    goToAll() {
      if (this.is_all) {
        this.paginated = false;
      } else { this.paginated = true; }
      this.getAll();
    },
    clearFilter() {
      this.motion = [];
      this.filters.teacher = undefined;
      this.filters.student = undefined;
      this.filters.edu_class = undefined;
      this.filters.from = undefined;
      this.filters.to = undefined;
      this.filters.grade = undefined;
      this.filters.subject = undefined;
      this.filters.angry = undefined;
      this.filters.disgust = undefined;
      this.filters.fear = undefined;
      this.filters.happy = undefined;
      this.filters.sad = undefined;
      this.filters.neutral = undefined;
      this.filters.surprise = undefined;
      this.filters.lesson_number = undefined;
      this.filters.engagement = undefined;
      this.getAll();
    },
    async getAll() {
      try {
        const filterTemp = {};
        Object.keys(this.filters).forEach((key) => {
          if (this.filters[key]) filterTemp[key] = this.filters[key];
        });
        this.loading = true;
        const res = await axios.get("/ai/teachers/details/group", {
          params: {
            size: 20,
            page: this.page,
            paginated: this.paginated,
            ...filterTemp,
          },
        });
        const arr = Array.from(res.data.data)
        if (Array.isArray(arr)) {
          console.log(arr);
          this.tableItems = arr.map((el) => {
            return {
              ...el,
            };
          });
        }
        console.log(res.data.data);
        this.tableItems.forEach((item) => {
          item.uid = this.$uuid.v1();
        });
        this.total = res.data.meta.total;
        this.page = res.data.meta.current_page;
      } finally {
        this.loading = false;
      }
    },
    applyPagination(paginationValue) {
      this.page = paginationValue;
      this.getAll();
    },
  },
  async created() {
    this.getAll();
  },
}
</script>

<style>
.add-user__chips-wrapper_Up {


  /* height: 400px; */
  border-radius: 5%;
  border: 1px solid #757575;
}

.add-user__chip_Up {}
</style>